/** Shamelessly stolen from starbucks */
import Axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './styles.scss';

const TIME_TO_FADE = 250;

class LoadingIndicator extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
  };

  static defaultProps = {
    active: false,
  };

  state = {
    working: this.props.active,
    fast: this.props.error && !this.props.active,
    fade: false,
  };

  element = React.createRef();

  addAnimationListener = callback => {
    this.element.current.addEventListener('animationiteration', callback);
  };

  removeAnimationListener = callback => {
    this.element.current.removeEventListener(
      'animationiteration',
      callback,
      false,
    );
  };

  startWorking = () => {
    this.setState({ working: true, fast: false });
  };

  stopAfterIteration = () => {
    this.setState({
      working: false,
      fade: false,
      fast: false,
    });
    this.removeAnimationListener(this.stopAfterIteration);
  };

  stopWorking = () => {
    this.addAnimationListener(this.stopAfterIteration);
  };

  switchToWorking = () => {
    this.setState({ working: false, fade: false, fast: true });

    setTimeout(() => {
      this.setState({ fade: true });

      setTimeout(() => {
        this.setState({ working: true, fade: false, fast: false });
      }, TIME_TO_FADE);
    }, 0);
  };

  fadeOut = () => {
    this.setState({ fade: true, working: false, fast: true });

    setTimeout(() => {
      this.setState({ working: false, fade: false, fast: false });
    }, TIME_TO_FADE);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.working && nextProps.active) {
      this.startWorking();
    } else if (this.state.working && !nextProps.active) {
      this.stopWorking();
    }
  }

  render() {
    const { fade, fast, working } = this.state;

    return (
      <div
        className={clsx('position-fixed sm-loadingIndicator', {
          'd-none': !working,
        })}
        aria-hidden="true"
      >
        <div
          ref={this.element}
          className={clsx('position-relative', {
            'sm-loadingIndicatorStatus--fast': fast,
            'sm-loadingIndicatorStatus--slow': !fast,
            'sm-loadingIndicatorStatus--working': working,
            'sm-loadingIndicatorStatus--fade': fade,
          })}
        >
          <div className="position-relative sm-loadingIndicator-stripe" />
        </div>
      </div>
    );
  }
}

const AsyncRequestIndicator = ({ client }) => {
  const [count, setCount] = useState(0);
  const requestRef = React.useRef(null);
  const responseRef = React.useRef(null);

  useEffect(() => {
    requestRef.current = client.interceptors.request.use(res => {
      setCount(prevCount => prevCount + 1);
      return res;
    });

    return () => client.interceptors.request.eject(requestRef.current);
  }, [client]);

  useEffect(() => {
    responseRef.current = client.interceptors.response.use(
      res => {
        setCount(prevCount => prevCount - 1);
        return res;
      },
      error => {
        setCount(prevCount => prevCount - 1);
        throw error;
      },
    );

    return () => client.interceptors.response.eject(responseRef.current);
  }, [client]);

  return <LoadingIndicator active={count > 0} />;
};

AsyncRequestIndicator.propTypes = {
  client: PropTypes.func.isRequired,
};

AsyncRequestIndicator.defaultProps = {
  client: Axios,
};

export default AsyncRequestIndicator;
