import apiClient from '../lib/api-client';
import { IAM_API_URL } from '../lib/api-client/api-urls';
import { pipeData } from '../lib/api-client/utils';
import { User } from '../types';

export const AUTH_USER_URL = `${IAM_API_URL}/users/profile`;

export type UserResponse = {
  user: User;
};

export const fetchAuthUser = () =>
  apiClient.get<UserResponse>(AUTH_USER_URL).then(pipeData(user => user));
