import { AxiosPromise } from 'axios';
import apiClient from '../lib/api-client';
import { IAM_API_URL } from '../lib/api-client/api-urls';
import { toData } from '../lib/api-client/utils';

const IAM_URL = `${IAM_API_URL}/auth/token`;

export type Credentials = {
  email: string;
  password: string;
};

export type Session = {
  token: string;
};

export const logIn = (credentials: Credentials): AxiosPromise<Session> =>
  apiClient.post(IAM_URL, credentials).then(toData);
