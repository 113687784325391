import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import FormGroup from '../FormGroup';
import InputFeedback from '../InputFeedback';
import Label from '../Label/Label';

export default function TextInput({
  error,
  helpText,
  label,
  name,
  onChange,
  required,
  showLabel,
  type,
  value,
  ...props
}) {
  return (
    <FormGroup>
      <Label htmlFor={name} srOnly={!showLabel} required={required}>
        {label}
      </Label>
      <input
        {...props}
        type={type}
        name={name}
        id={name}
        className={clsx('form-control', { 'is-invalid': Boolean(error) })}
        value={value}
        onChange={onChange}
        aria-required={required}
        aria-invalid={!!error}
        aria-describedby={helpText ? helpText.id : null}
      />
      {helpText}
      <InputFeedback error={error} />
    </FormGroup>
  );
}

TextInput.propTypes = {
  error: PropTypes.string,
  helpText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  type: PropTypes.oneOf(['email', 'password', 'text']).isRequired,
  value: PropTypes.string,
};

TextInput.defaultProps = {
  error: null,
  helpText: null,
  required: false,
  showLabel: true,
  type: 'text',
  value: '',
};
