import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';

const NotFound = () => (
  <div className="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center">
    <Helmet title="Not Found" />
    <Logo size="172px" />
    <div className="mt-3 w-50">
      <p>
        We couldn't find the page you were looking for: This is either because
      </p>
      <ul>
        <li>
          There was an error in the URL entered into your web browser. Please
          check the URL and try again.
        </li>
        <li>The page you were looking for has been moved or deleted.</li>
      </ul>
      <p>
        You can return to the home page by{' '}
        <Link to="/">
          <strong>clicking here</strong>
        </Link>
        .
      </p>
    </div>
  </div>
);

export default NotFound;
