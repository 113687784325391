import { getItemFromStorage } from '../../local-storage';

const useAuthenticationToken = config => {
  const token = getItemFromStorage('token');

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
};

export default useAuthenticationToken;
