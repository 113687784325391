/**
 * In reality, focus outlines are great for accessibility, but they usually look
 * ugly. Therefore, in order to provide the best experience lets override the
 * focus outline when a user is using the mouse, but if a keyboard event
 * is encountered, immediately show the outline to assist the user.
 */
/* eslint-disable */
/* https://github.com/alice/modality */

const focusSource = () => {
  var hadKeyboardEvent = false;
  var isHandlingKeyboardThrottle;

  var disableFocusRingByDefault = function disableFocusRingByDefault() {
    var css =
      'body:not([data-focus-source=keyboard]) :focus { outline: none; }';
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');

    style.type = 'text/css';
    style.id = 'disable-focus-ring';
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.insertBefore(style, head.firstChild);
  };

  var handleKeyDown = function handleKeyDown() {
    hadKeyboardEvent = true;
    if (isHandlingKeyboardThrottle) {
      clearTimeout(isHandlingKeyboardThrottle);
    }
    isHandlingKeyboardThrottle = setTimeout(function() {
      hadKeyboardEvent = false;
    }, 300);
  };

  var handleMouseDown = function handleMouseDown() {
    // Reset keyboard detection. This ensures that if a user
    // interacts with a component via keyboard (e.g. typing in a Field)
    // their input mode will be reset if they click on another element
    // within the 300ms keyboard focus throttle.
    hadKeyboardEvent = false;
  };

  disableFocusRingByDefault();

  document.body.addEventListener('keydown', handleKeyDown, true);
  document.body.addEventListener('mousedown', handleMouseDown, true);
  document.body.addEventListener('touchstart', handleMouseDown, true);

  document.body.addEventListener(
    'focus',
    function() {
      if (hadKeyboardEvent) {
        document.body.setAttribute('data-focus-source', 'keyboard');
      }
    },
    true,
  );

  document.body.addEventListener(
    'blur',
    function() {
      document.body.removeAttribute('data-focus-source');
    },
    true,
  );
};

export default focusSource;
