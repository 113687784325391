import PropTypes from "prop-types";
import React from "react";

export default function InputFeedback({ error, id }) {
  return error ? (
    <div id={id} className="invalid-feedback">
      {error}
    </div>
  ) : null;
}

InputFeedback.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string
};

InputFeedback.defaultProps = {
  error: null
};
