import '@reach/dialog/styles.css';
import React from 'react';
import 'react-day-picker/lib/style.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-source-sans-pro';
import 'typeface-work-sans';
import App from './App';
import './assets/styles/index.scss';
import AuthProvider from './auth/Login/AuthContext/AuthProvider';
import focusSource from './lib/focus_source';

focusSource();

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
