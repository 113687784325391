import axios, { AxiosInstance } from 'axios';
import useAuthenticatedHeadersIfPresent from './interceptors/use-authentication-token';

export type Client = AxiosInstance;

/**
 * API Client builder
 */
const toClient = (): Client => {
  const client = axios.create({
    responseType: 'json',
    headers: { 'X-SYMEND-PLATFORM': 'web-admin' },
  });

  client.interceptors.request.use(useAuthenticatedHeadersIfPresent);

  return client;
};

export default toClient();
