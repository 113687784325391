import PropTypes from 'prop-types';
import React from 'react';

/**
 * An SVG of the Symend Logo. The size and foreground colors are configurable
 * through props.
 */
const Logo = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 83.64 51.79"
    focusable={false}
  >
    <title>Symend</title>
    <path
      fill={color}
      d="M71.06,29.32a5.77,5.77,0,0,0-.44-2.26,5.35,5.35,0,0,0-1.25-1.82A5.83,5.83,0,0,0,67.43,24,6.78,6.78,0,0,0,65,23.6H60.68v1.82H65a4.39,4.39,0,0,1,1.62.29,3.62,3.62,0,0,1,1.26.83,3.71,3.71,0,0,1,.81,1.24,4.45,4.45,0,0,1,0,3.14,3.56,3.56,0,0,1-2.06,2,4.53,4.53,0,0,1-1.65.29H62.68V28.37h-2v6.71H65a6.68,6.68,0,0,0,2.47-.44,6,6,0,0,0,1.93-1.21,5.33,5.33,0,0,0,1.25-1.83,5.82,5.82,0,0,0,.44-2.27M55.73,31.54,49.59,23.6H47.72V35.08h2V26.91L56,35.08h1.69V23.6h-2Zm-19-3.16v6.71h8.58V33.26H38.73V30.19h5.76V28.37H36.73Zm8.49-4.77H36.73v1.82h8.49Zm-29.06,6.9,4.56-6.9H18.42l-3.25,5.08L12,23.6H9.58l4.56,7v4.53h2ZM31.78,6.89V21h2.07V9H74.61V49.72H33.86c0-8.76-.07-17.34-.07-26.12H31.63L28.14,29,24.65,23.6H22.5V35.08h2v-8.2l3.59,5.38h.07l3.63-5.41V51.79h44.9V6.89Zm46.67,38h5.18V0H38.73V5.12h2.07v-3H81.56V42.83H78.45Zm-76-18.3a1.22,1.22,0,0,1,.49-1,2.13,2.13,0,0,1,1.38-.4,4.21,4.21,0,0,1,1.56.3,7,7,0,0,1,1.56.88l1.08-1.52a6.76,6.76,0,0,0-1.89-1.07,6.56,6.56,0,0,0-2.27-.37,5,5,0,0,0-1.57.24,3.58,3.58,0,0,0-1.23.68,3.21,3.21,0,0,0-.81,1.06,3.15,3.15,0,0,0-.29,1.37A3.29,3.29,0,0,0,.7,28.14a2.64,2.64,0,0,0,.74,1,4.16,4.16,0,0,0,1.2.66,14.07,14.07,0,0,0,1.64.48,13.6,13.6,0,0,1,1.31.38,3.36,3.36,0,0,1,.79.39,1.12,1.12,0,0,1,.39.45,1.4,1.4,0,0,1,.11.56,1.25,1.25,0,0,1-.55,1.07,2.5,2.5,0,0,1-1.48.39,4.65,4.65,0,0,1-1.94-.39A7.1,7.1,0,0,1,1.21,32L0,33.41a6.92,6.92,0,0,0,2.25,1.38,7.23,7.23,0,0,0,2.54.45A5.66,5.66,0,0,0,6.44,35a3.66,3.66,0,0,0,1.29-.67,3.11,3.11,0,0,0,.84-1.08,3.42,3.42,0,0,0,.3-1.46,3,3,0,0,0-.24-1.25,2.71,2.71,0,0,0-.7-.93,4,4,0,0,0-1.16-.68,13.14,13.14,0,0,0-1.6-.51c-.55-.13-1-.26-1.36-.38A3.37,3.37,0,0,1,3,27.65a1.21,1.21,0,0,1-.42-.46,1.38,1.38,0,0,1-.11-.59"
    />
  </svg>
);

Logo.propTypes = {
  /**
   * Logo color
   */
  color: PropTypes.string.isRequired,
  /**
   * Size including unit
   */
  size: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  color: '#00a098',
  size: '50px',
};

export default Logo;
