import PropTypes from 'prop-types';
import React from 'react';

function FormGroup({ children }) {
  return <div className="form-group">{children}</div>;
}

FormGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default FormGroup;
