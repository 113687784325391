import { Formik } from 'formik';
import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { AuthContext } from './AuthContext/AuthProvider';
import LoginForm from './LoginForm';
import LoginValidations from './validations';

const Login = () => {
  const { logIn: submitForm } = useContext(AuthContext);

  return (
    <div className="d-flex vw-100 vh-100 justify-content-center align-items-center">
      <Helmet title="Sign In" />
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginValidations}
        validateOnBlur={false}
        validateOnChange={false}
        component={LoginForm}
        onSubmit={(values, formik) => {
          return submitForm(
            // we are trimming spaces at the beginning and end of username/email to prevent an error state
            //   in which it is hard for user to tell that there is an extra space somewhere
            {
              email: values.email.trim(),
              password: values.password,
            },
            {
              onError: () => {
                formik.setStatus({ message: 'Invalid email or password.' });
                formik.setSubmitting(false);
              },
            },
          );
        }}
      />
    </div>
  );
};

export default Login;
