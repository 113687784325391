import React, { ReactNode } from 'react';
import FullScreenCentered from './FullScreenCentered';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasChunkFailure: boolean;
  hasError: boolean;
};

type UncaughtError = Error;

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    hasChunkFailure: false,
    hasError: false,
  };

  static getDerivedStateFromError(error: UncaughtError) {
    if (error.message.includes('Loading chunk')) {
      return { hasChunkFailure: true };
    }

    return {
      hasError: true,
    };
  }

  componentDidCatch(error: UncaughtError, info: object) {
    // Log to Sentry
  }

  render() {
    const { hasChunkFailure, hasError } = this.state;

    if (hasChunkFailure) {
      return (
        <FullScreenCentered>
          We had an error loading the page you were expecting. Reload the page.
        </FullScreenCentered>
      );
    }

    if (hasError) {
      return (
        <FullScreenCentered>
          Something horrible happened. We've logged it and are investigating.
        </FullScreenCentered>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
