import { AxiosResponse } from 'axios';

/**
 * Extracts data property off a successful API call
 */
export const toData = <T>(response: AxiosResponse<T>) => response.data;

/**
 * Extracts data object and transforms response data
 */
export const pipeData = <T, U>(mapper: (a: T) => U) => (
  response: AxiosResponse<T>,
) => mapper(toData(response));
