import React from 'react';
import './styles.scss';

const FullPageSpinner = () => (
  <div
    data-testid="full-page-spinner"
    className="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center"
  >
    <div className="loading-spinner large">
      <div className="spinner-wheel" />
      <div className="spinner-ticks">
        {Array.from({ length: 32 }).map((_, idx) => (
          <div key={idx} className="spinner-tick" />
        ))}
      </div>
    </div>
  </div>
);

export default FullPageSpinner;
