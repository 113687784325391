import React from 'react';
import Button from '../../components/Button/Button';
import TextInput from '../../components/forms/TextInput';

const LoginForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  status,
  values,
}) => (
  <form onSubmit={handleSubmit}>
    {status && !isSubmitting && (
      <div className="alert alert-danger">{status.message}</div>
    )}
    <h1 className="pb-5">
      Welcome to Symend
      <small className="d-block text-muted">Enter your details below</small>
    </h1>

    <TextInput
      label="Email"
      name="email"
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder="Enter your email"
      value={values.email}
      error={errors.email}
      required
    />
    <TextInput
      type="password"
      label="Password"
      name="password"
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder="Enter your password"
      value={values.password}
      error={errors.password}
      required
    />

    <Button type="submit" disabled={isSubmitting}>
      Sign In
    </Button>
  </form>
);

export default LoginForm;
