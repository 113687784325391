import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import Login from '../../auth/Login';
import { AuthContext } from '../../auth/Login/AuthContext/AuthProvider';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={routeProps =>
        isAuthenticated ? <Component {...routeProps} /> : <Login />
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default ProtectedRoute;
