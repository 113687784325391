import React, { createContext } from 'react';
import { logIn } from '../../../api/auth';
import { fetchAuthUser } from '../../../api/user';
import { getItemFromStorage, storeItem } from '../../../lib/local-storage';
import resetApp from '../../../lib/reset-app';

const initialState = {
  user: null,
  isAuthenticated: false,
  logIn: () => {},
  logOut: () => {},
  updateUser: () => {},
};

export const AuthContext = createContext(initialState);

class AuthProvider extends React.Component {
  state = {
    user: null,
    isAuthenticated: Boolean(getItemFromStorage('token')) || false,
  };

  componentDidMount() {
    if (this.state.isAuthenticated) {
      this.fetchAuthedUser();
    }
  }

  componentDidUpdate() {
    if (this.state.isAuthenticated && !this.state.user) {
      this.fetchAuthedUser();
    }
  }

  handleLogin = (credentials, { onError }) =>
    logIn(credentials).then(
      ({ token }) => {
        storeItem('token', token);
        this.setState({ isAuthenticated: true });
      },
      error => {
        const errorMessage =
          (error.response && error.response.data.message) || error.message;
        onError(errorMessage);
      },
    );

  handleLogout = () => {
    resetApp(() => {
      this.setState({ isAuthenticated: false });
      window.location = '/';
    });
  };

  handleUserUpdate = user => {
    this.setState({ user });
  };

  fetchAuthedUser = () =>
    fetchAuthUser().then(({ user }) => {
      this.setState({ user });
    }, this.handleSessionExpired);

  handleSessionExpired = this.handleLogout;

  render() {
    const { isAuthenticated, user } = this.state;

    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          isAuthenticating: Boolean(isAuthenticated) && !user,
          logIn: this.handleLogin,
          logOut: this.handleLogout,
          updateUser: this.handleUserUpdate,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
