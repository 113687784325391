import clsx from 'clsx';
import React, { ReactNode } from 'react';

type ButtonProps = {
  block?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: VoidFunction;
  outline?: boolean;
  size?: string;
  type?: 'button' | 'reset' | 'submit';
  visualStyle?: string;
};

type Ref = HTMLButtonElement;

const Button = React.forwardRef<Ref, ButtonProps>(
  (
    {
      block = false,
      children,
      className,
      onClick,
      outline = false,
      size,
      type = 'button',
      visualStyle = 'primary',
      ...props
    },
    ref,
  ) => {
    const textLink = visualStyle === 'link';
    const textLinkClass = `btn btn-link ` + (className || '');

    const classList = textLink
      ? textLinkClass
      : clsx(
          'btn',
          {
            'btn-block': block,
            [`btn${outline ? '-outline' : ''}-${visualStyle}`]: !textLink,
          },
          {
            'btn-sm': size === 'sm',
            'btn-lg': size === 'lg',
          },
          className,
        );

    return (
      <button
        ref={ref}
        type={type}
        className={classList}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  },
);

export default Button;
