import React, { ReactNode } from 'react';

type FullScreenCenteredProps = {
  children: ReactNode;
};

const FullScreenCentered = ({ children }: FullScreenCenteredProps) => (
  <div className="vw-100 vh-100 d-flex flex-column align-items-center justify-content-center">
    {children}
  </div>
);

export default FullScreenCentered;
