import { clearStorage } from '../local-storage';

const resetApp = cb => {
  // localStorage access in a try-catch because even if supported,
  try {
    clearStorage();
  } catch (e) {} // eslint-disable-line no-empty

  if (cb) {
    cb();
  }
};

export default resetApp;
