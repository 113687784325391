import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

function Label({ children, htmlFor, required, srOnly, ...props }) {
  const classes = clsx({ 'control-label': true, 'sr-only': srOnly });

  return (
    <label className={classes} htmlFor={htmlFor} {...props}>
      {children} {!required && <small className="text-muted">Optional</small>}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  htmlFor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  required: PropTypes.bool,
  srOnly: PropTypes.bool,
};

Label.defaultProps = {
  required: true,
  srOnly: false,
};

export default Label;
