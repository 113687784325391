import React, { Suspense, useContext } from 'react';
import Helmet from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from './auth/Login/AuthContext/AuthProvider';
import Button from './components/Button';
import AsyncRequestIndicator from './layout/AsyncRequestIndicator';
import ErrorBoundary from './layout/ErrorBoundary';
import FullPageSpinner from './layout/FullPageSpinner';
import NotFound from './layout/NotFound';
import ProtectedRoute from './layout/ProtectedRoute';

const App = () => {
  const { isAuthenticating, isAuthenticated, logOut } = useContext(AuthContext);

  if (isAuthenticating) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <AsyncRequestIndicator />
      <Helmet titleTemplate="%s - Symend" defaultTitle="Symend" />
      <ErrorBoundary>
        <Suspense fallback={<FullPageSpinner />}>
          <Switch>
            <ProtectedRoute
              path="/dashboard"
              component={React.lazy(() => import('./dashboard'))}
            />
            <Redirect from="/" to="/dashboard" />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
        {isAuthenticated && (
          <div className="flex justify-center items-center py4">
            <Button visualStyle="dark" size="sm" onClick={logOut}>
              Logout
            </Button>
          </div>
        )}
      </ErrorBoundary>
    </>
  );
};

export default App;
